import qs from "qs";

export default ({ $axios, store, $device, redirect, $cookies, app: { router, $modal } }) => {
  let path = undefined;
  let query = undefined;
  let partPath = undefined;

  $axios.defaults.paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  };

  $axios.interceptors.request.use(
    (config) => {
      if ($device.isMobile) {
        config.headers["X-Device"] = "20";

        if(store.state.layout.isApp){
          if($device.isAndroid){
            //android
            config.headers["X-IS-APP"] = "10";
          }else{
            //ios
            config.headers["X-IS-APP"] = "20";
          }
        }else{
          config.headers["X-IS-APP"] = "0";
        }
        
      } else {
        config.headers["X-Device"] = "10";
        config.headers["X-IS-APP"] = "0";
      }

      if( config.url.startsWith('/') ) {
        //set IP
        config.headers["X-BROWSER-SOURCE-IP"] = store.state.layout.ipaddr || "";
      }
      
      return config;
    },
    (config) => {
      return Promise.reject(config);
    }
  );

  $axios.onError((error) => {
    let data = ((error || {}).response || {}).data;
    let apiUrl = (data || {}).path || '';

    if (error.response.status === 401) {
      if(apiUrl == "/api/remember/login"){
        //자동로그인 Exception은 실패할경우 가만히 있는다.
        return;
      } else {
        return redirect("/");
      }
      //return redirect("/");
    } else if (error.response.status === 403) {
      
      if (path == "/login") {
          return;
        } else if( error.response.data.code == 'USER_NOT_AUTHORIZED' ) {
          // 1) 서버의 session과 동기화
          try {
            if(partPath) {
              if(partPath == "/conversion") return
              $cookies.set("LOGIN_REDIRECT", partPath);
              $cookies.set("LOGIN_REDIRECT_QUERY", (query || {}) );
            }
            $axios.$get("/api/user/me")
            .then((profile) => {
              store.commit("session/set", profile);
            })
            .catch((e) => {
              store.commit("session/clear");
            })
            .finally(() => {
              // 2) 다시 돌아가기위한 redirect
              if(process.client) {
                //대부분 process.client 일듯
                window.location.href = '/login';
              }
            });

            let utm_source ;
            let utm_campaign ;
            let utm_medium ;
            let urlPath = '/login';
            if (query) {
             utm_source = query.utm_source;
             utm_campaign = query.utm_campaign;
             utm_medium = query.utm_medium;    
             urlPath = `/login?${qs.stringify({utm_source,utm_medium,utm_campaign})}`
            } 

            return redirect(urlPath);
          } catch (e) {
            return redirect("/login");
          }
        }

    }
  });

  router.beforeEach((to, from, next) => {
    try {
      let lastPath = to.path || '';
      let redirectExcept = /join|login/;

      if( !lastPath.match(redirectExcept) ){
        path = to.fullPath;
        query = to.query;
        partPath = to.path;
      }
      /*
      if (to.fullPath !== "/login") {
        path = to.fullPath;
      }
      */
    } catch (e) {
      // Error occurred
    }
    next();
  });
};
