<template>
  <div :class="{ 'mopop-wrapper': isMenuOpen }" :style="{ display: modalCategoryStyle }">
    <div class="mobpop_menu">
      <div class="mob_hd">
        <a href="javascript:;" aria-label="HOME 이동" @click.prevent="doRoute('/')" class="hd_logo">까웨(K-WAY)</a>
        <div class="login_state">
          <!-- s : 로그인 전 -->
          <div class="login_link" v-if="$store.state.session.isMember === false">
            <a href="javascript:;" aria-label="로그인 페이지 이동" @click.prevent="doRoute('/login')">로그인</a>
            <a href="javascript:;" aria-label="회원가입 페이지 이동" @click.prevent="doRoute('/join')">회원가입</a>
          </div>
          <!-- e : 로그인 전 -->
          <!-- s : 로그인 후 -->
          <div class="login_after" v-if="$store.state.session.isMember === true">
            <p class="user_nm">
              <span>{{ $store.state.session.profile.name }}</span> 님
            </p>
          </div>
          <div class="login_link" v-if="$store.state.session.isMember === true">
            <a href="javascript:;" aria-label="로그아웃 버튼" @click.stop="onClickLogout()">로그아웃</a>
          </div>
          <!-- // e : 로그인 후 -->
        </div>
      </div>
      <div class="menu_scroll">
        <div class="menu_listcon">
          <ul class="menu_li">
            <li><a href="javascript:;" aria-label="신상품 페이지 이동" @click.prevent="doRoute('/main/new')">NEW</a></li>
            <li><a href="javascript:;" aria-label="인기상품 페이지 이동" @click.prevent="doRoute('/main/best')">BEST</a></li>
            <!--li><a href="/promotion/574" aria-label="윈드브레이커 기획전 이동">CHALLENGE</a></li-->
            <li><nuxt-link to="/promotion/661" style="color: #f30000;">HAPPY 2025</nuxt-link></li>
            <li><a href="javascript:;" aria-label="남성 일반상품 카테고리 이동" @click.prevent="doRoute('/category?id=10&gender=M')">MEN</a></li>
            <li><a href="javascript:;" aria-label="여성 일반상품 카테고리 이동" @click.prevent="doRoute('/category?id=10&gender=F')">WOMEN</a></li>
            <li><a href="javascript:;" aria-label="키즈 일반상품 카테고리 이동" @click.prevent="doRoute('/category?id=10&gender=K')">KIDS</a></li>
            <!--<li><a href="javascript:;" aria-label="스페셜 페이지 이동" @click.prevent="doRoute('/special')">SPECIAL</a></li>-->
            <li><a href="javascript:;" aria-label="아울렛 카테고리 이동" @click.prevent="doRoute('/category?id=15')">OUTLET</a></li>
          </ul>
          <ul class="menu_ectli">
            <li>
              <a href="javascript:;" aria-label="마이페이지 이동" @click.prevent="doRoute('/my-page/main')">MYPAGE</a>
              <a href="javascript:;" aria-label="이벤트 페이지 이동" @click.prevent="doRoute('/event')">EVENT</a>
              <a href="javascript:;" aria-label="기획전 페이지 이동" @click.prevent="doRoute('/promotion')">기획전</a>
              <a href="javascript:;" aria-label="까웨 스토리 페이지 이동" @click.prevent="doRoute('/footer/story')">K-WAY STORY</a>
              <a href="javascript:;" aria-label="까웨 피플 페이지 이동" @click.prevent="doRoute('/kway-people')">K-WAY PEOPLE</a>
              <a href="javascript:;" aria-label="콜렉션 페이지 이동" @click.prevent="doRoute('/footer/collection')">COLLECTION</a>
              <a href="javascript:;" aria-label="베스트 리뷰 이동" @click.prevent="doRoute('/main/review')">REVIEW</a>
            </li>
            <li>
              <a href="javascript:;" aria-label="매장정보 페이지 이동" @click.prevent="doRoute('/footer/store')">STORE</a>
              <a href="javascript:;" aria-label="CS문의 페이지 이동" @click.prevent="doRoute('/customer/main')">CS CENTER</a>
            </li>
          </ul>
        </div>
        <!-- s : 로그인 후 -->
        <div class="etc_link" v-if="$store.state.session.isMember === true">
          <a href="javascript:;" aria-label="로그아웃 버튼" @click.stop="onClickLogout()">로그아웃</a>
        </div>
        <!-- e : 로그인 후 -->
      </div>
      <button class="mob_close" aria-label="Modal 닫기 버튼" @click.prevent="openCategoryModal()">닫기</button>
    </div>
  </div>
</template>

<script>
import Common from '@/assets/mixins/common'

export default {
  mixins: [ Common ],
  props: ["modalCategoryStyle"],
  data: () => ({
    // categories: [],
  }),
  computed: {
    isMenuOpen() {
      return this.modalCategoryStyle === "block" ? true : false;
    },
  },
  methods: {
    async onLogoutButtonClicked() {
      await this.$axios.$post("/api/logout");
      this.$store.commit("session/clear");
      this.setCookie("AUTO_LOGIN", "N", 0);
      try {
        window.cremaAsyncInit = function () {
          crema.init(null, null);
        };
        window.cremaAsyncInit();
      } catch (error) {
        // console.log(`Crema init error\n> ${error}`)
      }
      //await this.$router.push("/");
      window.location = "/";

      //await this.$router.push("/", () => window.location.reload());
    },
    openCategoryModal() {
      this.$emit("openCategoryModal");
    },
    doRoute(path) {
      this.$router.push(path);
      // 400ms 이후 꺼짐
      setTimeout(() => this.$emit("openCategoryModal"), 400);
    },

    async getCookie (name) {
      var value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
      return value? value[2] : null;
    },

    async setCookie (name, value, exp) {
      var date = new Date();
      date.setTime(date.getTime() + (exp*24*60*60*1000) );
      document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
    },
  },
};
</script>

<style scoped>
.mopop-wrapper {
  z-index: 9998;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vh;
  height: calc(100vh - 60px);
  background: rgba(0, 0, 0, 0.8);
}
</style>
