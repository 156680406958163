<template>
  <div class="popup_layer sns">
    <!-- sns 공유 -->
    <div class="popup_header">
      <h5 class="subcon_tit">공유하기</h5>
      <button type="button" class="bt_closepop" @click.stop="$emit('close')">닫기</button>
    </div>
    <div class="popup_contents">
      <div class="sns_wrap">
        <a class="sns_box facebook link" @click.prevent="shareWithFacebook(shareUrl)"><span>Facebook</span></a>
        <a class="sns_box kakaotalk link" @click.prevent="shareWithKakaoTalk(shareUrl)"><span>Kakao Talk</span></a>
        <!--앱에서만 추가<a class="sns_box insta" @click.prevent="copyToClipboard(shareUrl)"><span>INSTAGRAM</span></a>-->
        <!--<a class="sns_box pinterest link" @click.prevent="shareWithPinterest(shareUrl, shareTitle, shareImageUrl)">Pinterest</a>-->
        <!--<a class="sns_box twitter link" @click.prevent="shareWithTwitter(shareUrl, shareTitle)">Twitter</a>-->
        <a class="sns_box url" @click.prevent="copyToClipboard(shareUrl)"><span>URL 복사</span></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shareUrl: String,
    shareTitle: String,
    shareImageUrl: String,
    inflowMethod: {
      type: String,
      default: () => 'SHARE',
    },
    utmSource: {
      type: String,
      default: () => 'SHARE'
    }
  },

  methods: {
    shareWithKakaoTalk(shareUrl) {
      try {
        Kakao.Link.sendScrap({
          requestUrl: this.getShareUrl(shareUrl)
        });
        this.$emit("close");
      } catch (e) {
        this.$modal.error("카카오톡 공유에 실패하였습니다.");
      }
    },
    shareWithFacebook(shareUrl) {
      if (this.$store.state.layout.isApp) {
        try {
          window.skyDroidIF.shareSns({
            type: "FACEBOOK",
            shareUrl: this.getShareUrl(shareUrl),
            shareTitle: null,
            shareImageUrl: null
          });
        } catch (e) {
          this.$modal.error("페이스북 공유에 실패하였습니다.");
        } finally {
          this.$emit("close");
        }
      } else {
        window.open("https://www.facebook.com/sharer/sharer.php" +
          "?u=" + this.getShareUrl(shareUrl));
        this.$emit("close");
      }
    },
    shareWithPinterest(shareUrl, shareTitle, shareImageUrl, retry) {
      this.$loadScript("//assets.pinterest.com/js/pinit.js")
        .then(() => {
          try {
            PinUtils.pinOne({
              url: this.getShareUrl(shareUrl),
              media: this.getImageUrl(shareImageUrl),
              description: shareTitle
            });
          } catch (e) {
            if (retry) {
              this.$modal.error("핀터레스트 공유에 실패하였습니다.");
            } else {
              setTimeout(() => this.shareWithPinterest(shareUrl, shareTitle, shareImageUrl, true), 200);
            }
          } finally {
            this.$emit("close");
          }
        });
    },
    shareWithTwitter(shareUrl, shareTitle) {
      window.open("https://twitter.com/intent/tweet?" +
        "&text=" + encodeURIComponent(shareTitle) +
        "&url=" + encodeURIComponent(this.getShareUrl(shareUrl)));
      this.$emit("close");
    },
    copyToClipboard(shareUrl) {
      let t = document.createElement("textarea");
      document.body.appendChild(t);
      t.value = this.getShareUrl(shareUrl);
      t.select();
      document.execCommand("copy");
      document.body.removeChild(t);

      this.$modal.success("URL이 클립보드에 복사되었습니다.").then(() => this.$emit("close"));
    },

    getShareUrl(shareUrl) {
      var url = new URL(shareUrl);
      const searchParams = url.searchParams;
      searchParams.append('inflowMethod', 'SHARE');
      searchParams.append('utm_source', 'SHARE');

      return `${url.origin}${url.pathname}?${searchParams.toString()}`;
    }
  }
}
</script>
