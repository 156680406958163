export const state = () => ({
    form: {},
    event: {},
  });
  
  export const mutations = {
    setForm(state, form) {
      state.form = form;
    },
    setEvent(state, event) {
      state.event = event;
    },
  };
  
  export const actions = {
    updateForm({ commit }, form) {
      commit('setForm', form);
    },
    updateEvent({ commit }, event) {
      commit('setEvent', event);
    },
  };
  
  export const getters = {
    form: (state) => state.form,
    event: (state) => state.event,
  };