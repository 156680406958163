<template>
  <transition name="fade">
    <template v-if="$device.isMobile">
      <div class="gotop_wrap" v-if="scrollDown">
        <button
          type="button"
          class="gotop"
          @click.stop="moveTop()"
        >
          맨 위로 이동
        </button>
      </div>
    </template>

    <template v-else>
      <div class="gotop_wrap" v-if="scrollDown">
        <button v-if="showShareButton" type="button" class="top_b share_button" @click.stop="openShareModal()"> </button>
        <button type="button" class="top_b" @click.stop="moveTop()"> </button>
        <button type="button" class="btm_b" @click.stop="moveBottom()"> </button>
      </div>
    </template>
  </transition>
</template>

<script>
import ShareModal from "@/components/commons/modal/ShareModal";

export default {
  name: "TopButton",
  data() {
    return {
      scrollDown: false,
      showShareButton: false,
    };
  },
  computed: {
    form() {
      return this.$store.state.form.form;
    },
    event() {
      return this.$store.state.form.event;
    }
  },
  mounted() {
    window.addEventListener("scroll", () => {
      if ((window.scrollY || window.pageYOffset) > 200) {
        this.scrollDown = true;
      } else {
        this.scrollDown = false;
      }
    });
    const promotionOrEventRegex = /\/(promotion|event)\/\d+$/;
    this.showShareButton = promotionOrEventRegex.test(this.$route.path);
  },
  watch: {
    $route(to, from) {
      const promotionOrEventRegex = /\/(promotion|event)\/\d+$/;
      this.showShareButton = promotionOrEventRegex.test(to.path);
    }
  },
  methods: {
    moveTop() {
      window.scrollTo(0, 0);
      this.scrollDown = false;
    },
    moveBottom() {
      window.scrollTo(0,document.body.scrollHeight);
      this.scrollDown = false;
    },
    openShareModal() {
      const promotionRegex = /\/promotion\/\d+$/;
      const eventRegex = /\/event\/\d+$/;

      try{
        if (promotionRegex.test(this.$route.path)) {
          this.$modal.show(
            ShareModal,
            {
              shareTitle: this.form.exhbtNm,
              shareUrl: this.getUrl(`/promotion/${this.form.exhbtNo}`),
              shareImageUrl: this.getImageUrl(this.$device.isMobile ? this.form.gdBnrMoImgPath : this.form.gdBnrImgPath)
            },
            {
              width: "200px",
              height: "auto",
            }
          );
        } else if (eventRegex.test(this.$route.path)) {
          this.$modal.show(
            ShareModal,
            {
              shareTitle: this.event.ttl,
              shareUrl: this.getUrl(`/event/${this.event.eventNo}`),
              shareImageUrl: this.getImageUrl(this.event.dlgtImgPath)
            },
            {
              width: "200px",
              height: "auto",
            }
          );
        }
      }catch(e){
        console.error(e);
        this.$modal.error('공유 모달을 여는 중 오류가 발생했습니다.');
      }
      
    },
  },
};
</script>
<style scoped>
.share_button{
  margin-bottom: 10px;
}
</style>